/* globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "antd/dist/reset.css";

body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}
/* // fix Tailwind CSS border styles,form Tailwind CSS's preflight */
*,
::before,
::after {
  /* box-sizing: border-box; 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
}

::before,
::after {
  --tw-content: "";
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.form {
  margin: 0 !important;
}

.customab .swiper {
  width: 100%;
  height: 100%;
}
.customab .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customab .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.customab .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.customab .swiper-slide {
  background-size: cover;
  background-position: center;
}
.customab .mySwiper2 {
  height: 80%;
  width: 100%;
}
.customab .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 26px 0 0 0;
}
.customab .mySwiper .swiper-slide {
  height: 100%;
  opacity: 1;
  margin-right: 15px !important;
}
.customab .mySwiper .swiper-slide-thumb-active {
  opacity: 0.4;
}
.customab .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rating:not(:checked) > input {
  position: absolute;
  appearance: none;
}
.rating:not(:checked) > label {
  float: right;
  cursor: pointer;
  font-size: 30px;
  fill: #666;
}
.rating:not(:checked) > label > svg {
  fill: #666; /* Set default color for SVG */
  transition: fill 0.3s ease; /* Add a transition effect */
}
.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  fill: #e58e09;
}
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  fill: #ff9e0b;
}
.rating > input:checked ~ label > svg {
  fill: #ffa723; /* Set color for selected stars */
}
.customab .swiper-button-next,
.customab .swiper-button-prev {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 40px;
  max-height: 40px;
  background-repeat: no-repeat;
}
.customab .swiper-button-prev {
  background-image: url("/public/pre.svg");
}
.customab .swiper-button-next {
  background-image: url("/public/next.svg");
}
.customab .swiper-button-next::after,
.customab .swiper-button-prev::after {
  content: "";
}

.input-otp {
  gap: 2em;
}

/* .input-otp__field {
  max-width: 64px;
} */

.ant-collapse-content-active {
  border-top: 1px solid #d9d9d9;
}

.avatar-uploader .ant-image .ant-image-mask:hover {
  border-radius: 50%;
  overflow: hidden;
}
